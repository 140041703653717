<template>
  <div class="news-detail">
    <div class="news-detail">
      <div v-if="currentArticleId === '1'" class="news-article">
        <div class="article-title">垃圾分类就是新时尚 | 群峰行动</div>
        <div class="article-content">
          <p class="article-item">
            为响应政府号召，培养居民生活垃圾日常分类习惯，促进生活垃圾分类减量的目的，群峰一直在探索新的模式，“童子街庭苑”就是其中一项新的举措。
          </p>
          <p class="article-item">
            童子街庭苑试运行2年以来，已有注册户994户，覆盖了小区96.8%的住户，其中活跃用户704户，占总户数的68.6%，居民参与热情较高。截至2021年2月4日，整个小区共收集可回收物78.79吨、餐厨垃圾174.2吨、其他垃圾408.9吨、有害垃圾290.05公斤。小区居民通过奖励积分提现共6.6万余元，其中可回收垃圾奖励6.2万元、厨余垃圾和其他垃圾奖励3688.6元。
          </p>
          <p class="article-item">
            该小区共有12个单元楼，每个单元楼门口都摆放着一台小型垃圾投放装置，我们简称它为“小岛”。两个投放箱被自动盖子遮挡着，分别用以容纳厨余垃圾和其他垃圾。居民投放了垃圾会获得相应的积分奖励。
          </p>
          <p class="article-item">
            每个“小岛”上都印有用户操作说明，居民可以选择一键投放、刷卡投放和扫码投放三种方式。
          </p>
          <p class="article-item">
            一键投放：和传统扔垃圾没有太大区别，只需按一下按钮，箱门就会自动打开，把垃圾投放进去就好了，但是就没有了积分奖励；
          </p>
          <p class="article-item">
            刷卡投放和扫码投放：通过刷卡和扫码的方式登录进个人账户（一户共享一个账户），投放完毕后设备会根据投放重量结算积分到居民账户中。有了这个垃圾智能收集设备，如今垃圾桶周围比以前干净卫生了许多。
          </p>
          <p class="article-item">
            在小区另一个地方，安放着一台大型装置，是小区引进的“大岛”垃圾智能收集设备。与“小岛”相比，“大岛”用于回收泡沫、塑料瓶、织物等各种可回收物，还有电池、药品等有害垃圾。
          </p>
          <p class="article-item">
            “大岛”把可回收物细分成了13类：黄纸皮、小家电、书报、铝制品、铁制品、织物、塑料瓶、玻璃、一般塑料、其他塑料、泡沫、其他纸皮，每个柜子上贴有相应常见的物品列举，一目了然。最中间就是有害垃圾啦，包括废电池、药品、废旧灯管。
          </p>
          <p class="article-item">
            “大岛”每天下午16:00——19:00定时开放，一到下午4点，就陆续有居民来投放垃圾了，刷卡或扫码进入账户，开始计重，显示屏上会显示当前投放了哪些垃圾，重量是多少，完成后点击“投放完毕”就可以结束计重。
          </p>
          <p class="article-item">
            当然这些物品都是有价值的，在“大岛”旁边这些物品都被“明码标价”，居民投放了垃圾，也获得了收益。“大岛”开放期间会有垃圾分类员在旁作指导，但是小编看到大家都已经十分了解投放操作了，上到老人，下到小朋友，都会使用哦~
          </p>
          <p class="article-item">
            群峰不仅在垃圾分类上采用智能设备，分类后的垃圾怎么处理呢？
          </p>
          <p class="article-item">
            为响应政府的源头减量号召，避免从前的“前分后混”行为，做到分类投放、分类收集、分类运输、分类处理，群峰还引入了厨余垃圾处理设备，采用好氧微生物高温发酵工艺降解厨余垃圾，满负荷处理量为300公斤/天，可降解85%的重量，剩下15%为产出的营养土，营养土可作为肥料用于栽种花草树木，肥沃泥土。
          </p>
          <p class="article-item">
            门外就是一个特别的花园，这里是小区的“厨余垃圾环保魔法屋”，有食用油桶做的花盆，废弃轮胎做的花坛，生机勃勃的青菜，绿意嫣然的植物……
          </p>
          <p class="article-item">
            以前，垃圾要拉到焚烧填埋场去焚烧或填埋，运输中的残汤剩水也会影响市容市貌，并且耗费人力物力。现在，该小区的厨余垃圾自行就解决了，可回收物每天会有工作人员运送到相应地方进行回收二次利用，有害垃圾也会拿去集中销毁；所以清运人员只需要每天来拉走“其他垃圾”就好了。从源头上就大大减少了垃圾的重量。
          </p>
        </div>
      </div>
      <div v-else class="news-article">
        <div class="article-title">镜头下，童子街庭苑居民怎么说</div>
        <div class="article-content">
          <p class="article-item">
            昨日（3月25日）成都电视台的记者来到童子街庭苑，了解智能垃圾回收箱的使用情况，并且采访了小区的居民，那么实际情况怎么样呢？
          </p>
          <p class="article-item">
            小区居民都知道，我们的智能垃圾回收箱是每天定时定点开放，在记者采访时，正好到了我们的指定开放时间。
          </p>
          <p class="article-item">
            很多居民都提前把分好类的垃圾带到回收点等候，小编看着他们操作熟练的把每一个物品放到对应的回收口，再按下投放完毕。其中一位阿姨转过身来笑眯眯的说“又赚了6块钱”。
          </p>
          <p class="article-item">
            以前，大家只能在我们垃圾分类引导员的引导下进行投放；现在如果有不懂的，除了我们的引导员，旁边的叔叔阿姨也会教你哦！
          </p>
          <p class="article-item">
            记者还采访了来投放垃圾的叔叔阿姨，大家都是一致的好评。一位阿姨说，现在成都开始垃圾分类，他们小区却从两年前就开始了，看着现在小区环境越来越好，垃圾还能直接换钱，一举多得，她和邻居们都表示非常支持这项工作。
          </p>
          <p class="article-item">
            还有位叔叔感叹，这两年我们的工作人员确实很不容易，克服了很多的困难才有今天井井有条的样子，以前小区居民不懂，不愿意参与，工作人员都是挨家挨户上门宣传。现在大家对这种分类模式十分认可。
          </p>
          <p class="article-item">
            我们也在此感谢这些积极参与垃圾分类的居民们，因为有了你们的配合，我们的工作才会有条不紊的开展，你们就是大家身边最美的生活垃圾分类达人。俗话说“行百里者半九十”，未来的路还有很多困难等着我们克服，但是只要得到了大家的认可，我们对这份工作就更加充满热情。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewsDetail',
  data () {
    return {
      // 当前文章id
      currentArticleId: '1'
    }
  },

  created () {
    this.currentArticleId = this.$route.query.id || '1'
  },

  methods: {
    
  }

}

</script>

<style lang="scss" scoped>

.news-article {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  .article-title {
    text-align: center;
    margin: 15px 0;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .article-content {
    display: flex;
    flex-direction: column;
    .article-item {
      margin-bottom: 15px;
      font-size: 14px;
      color: #555;
      line-height: 1.5;
      text-indent: 2rem;
    }
  }
}

</style>
